<template>
    <div
        class="edit-wrap"
        v-loading="loading"
    >
        <div class="white-bg">
            <div class="edit-hd">
                <div class="btn-wrap">
                    <el-button @click="back">返回</el-button>
                    <el-button @click="submitForm(2)">保存至草稿箱</el-button>
                    <el-button @click="submitForm(3)">发布</el-button>
                </div>
                <h4 class="title">发布资源</h4>
            </div>
            <div class="edit-bd article_edit_box">
                <el-form>
                    <div class="edit-box">
                        <el-input
                            placeholder="标题"
                            type="text"
                            v-model="title"
                            class="title"
                            v-on:input="titleInput"
                        ></el-input>
                        <el-upload
                            :show-upload-list="false"
                            :on-success="handleSuccess"
                            :format="['jpg','jpeg','png','gif']"
                            :before-upload="handleBeforeUpload"
                            type="drag"
                            :action="imgUploadUrl"
                            class="uploadImage"
                            :options="editorOption"
                            ref="QuillEditor"
                        >
                        </el-upload>
                        <span class="title_tip">{{fontCount}}/35字</span>
                        <quill-editor
                            v-model="content"
                            :class="contentTips"
                            style="height: 500px;"
                            @change="$event.quill.deleteText(5000, 1, formData.introduction)"
                            :options="editorOption"
                            ref="QuillEditor"
                        >
                        </quill-editor>
                    </div>

                    <el-form-item label="* 选择分类">
                        <el-select
                            v-model="cate_id"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in cateList"
                                :key="item.id"
                                :value="item.id"
                                :label="item.title"
                            ></el-option>
                        </el-select>
                    </el-form-item>

                    <!-- <el-form-item label="标签">
                    <el-input
                        type="text"
                        v-model="tags"
                        placeholder="请输入标签，标签之间用，号隔开"
                    ></el-input>
                    </el-form-item>-->

                    <el-form-item label="缩略图">
                        <el-upload
                            :action="imgUploadUrl"
                            :headers="imgHeaders"
                            :auto-upload="true"
                            :multiple="false"
                            :before-upload="beforeFileUpload"
                            :on-success="handleAvatarSuccess"
                            list-type="text"
                        >
                            <i
                                class="el-icon-plus"
                                v-if="!img"
                            ></i>
                            <img
                                v-if="img"
                                :src="util.reImg(img)"
                                class="avatar"
                                style="width:146px;height:146px;border-radius:5px;"
                            />
                        </el-upload>
                    </el-form-item>

                    <el-form-item label="上传附件">
                        <p class="tips">您最多可以上传5个附件，每个附件不超过15M，格式为JPG、PNG、BMP、DOC、PDF、或RAR,7Z等压缩包文件</p>
                        <!-- <p class="tips">最多可上传3个附件，每个小于5M文件，格式为JPG,PNG DOC PDF或BMP</p> -->
                        <ul class="upload-items">
                            <li
                                class="upload uploaded"
                                v-for="item in fileLists"
                                :key="item.hash"
                            >
                                <span class="icon">
                                    <img :src="$img.upload_icon2" />
                                </span>
                                <div class="upload-txt">
                                    <h4
                                        class="name"
                                        v-text="item.name"
                                    ></h4>
                                    <p>
                                        <strong
                                            class="size"
                                            v-text="item.size"
                                        ></strong>
                                        <span class="date">{{item.date}}上传</span>
                                    </p>
                                </div>

                                <div class="opera">
                                    <div
                                        class="tips"
                                        @click="delAnnex(item.hash)"
                                    >删除</div>
                                </div>
                            </li>
                            <li class="upload">
                                <el-upload
                                    class="file-uploader"
                                    :action="imgUploadUrl+'&thumb_type=article'"
                                    :headers="imgHeaders"
                                    :auto-upload="true"
                                    :on-success="handleFileSuccess"
                                    :before-upload="beforeFileUpload"
                                    :on-progress="onUploadProgress"
                                    :on-error="onError"
                                >
                                    <div class="upload-txt">
                                        <span class="icon">
                                            <img :src="$img.upload_icon1" />
                                        </span>
                                        <strong>点击上传</strong>
                                    </div>
                                </el-upload>
                            </li>
                            <li>
                                <el-progress
                                    v-if="uploadPress>0"
                                    style="width:550px"
                                    :percentage="uploadPress"
                                ></el-progress>
                            </li>
                        </ul>
                    </el-form-item>

                    <el-form-item label="下载附件是否需要支付：">
                        <el-radio-group
                            v-model="radio"
                            @change="change"
                        >
                            <el-radio :label="1">否</el-radio>
                            <el-radio :label="2">是</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item
                        label="下载需点券"
                        v-if="radio == 2"
                    >
                        <div class="downlaod_count_item">
                            <el-input
                                v-model="download_point_count"
                                type="number"
                                @input="downloadPointInput"
                            ></el-input>
                            <span>&nbsp;&nbsp;点券</span>
                        </div>

                        <div
                            class="downloadPointTips downlaod_count_item"
                            v-text="downloadPointTips"
                            v-if="downloadPointTips"
                        ></div>
                    </el-form-item>

                    <!-- <el-form-item
                        label="下载需费用"
                        v-if="radio == 2"
                        style="padding-bottom:0px;"
                    >
                        <div class="downlaod_count_item">
                            <el-input
                                v-model="download_price"
                                type="number"
                                @input="downloadPriceInput"
                            ></el-input>
                            <span>&nbsp;&nbsp;元</span>
                        </div>

                        <div
                            class="downloadPointTips downlaod_count_item"
                            v-text="downloadPriceTips"
                            v-if="downloadPriceTips"
                        ></div>
                    </el-form-item>-->

                    <!-- <el-form-item
                        label="下载需费用"
                        v-if="radio == 2"
                    >
                        <el-input
                            v-model="download_price"
                            style="width: 200px;"
                            type="number"
                            @input="downloadPriceInput"
                        ></el-input>&nbsp;&nbsp;元
                    </el-form-item>-->

                    <el-form-item class="article_save">
                        <span>
                            <el-button @click="submitForm(2)">保存至草稿箱</el-button>
                            <el-button @click="submitForm(3)">发布</el-button>
                        </span>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import TopNav from "@/components/common/TopNav.vue";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import GlobalFooter from "@/components/common/GlobalFooter.vue";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

// 工具栏配置
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['link', 'image'],
    ['clean']                                        // remove formatting button
]
export default {
    name: "edit",
    metaInfo() {
        return {
            title: "发布资源 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    components: {
        TopNav, // 头部吊顶
        BreadCrumb, // 面包屑
        GlobalFooter, // 公共底部
        quillEditor //编辑器
    },
    data() {
        return {
            radio: 1,
            fontCount: 0,
            title: "",
            content: "",
            cate_id: "",
            tags: "",
            img: "/upload/image/geek_avatar.png",
            download_point_count: 0,
            download_price: 0,
            cateList: {},
            imgUploadUrl: "",
            imgHeaders: {
                token: "",
                timestamp: "",
                secret: ""
            },
            loading: false,
            fileLists: [],
            fileName: "",
            id: "",
            uploadPress: 0,
            downloadPointTips: "",
            downloadPriceTips: "",
            contentTips: "contentFontColorGreen",
            editorOption: {
                placeholder: '您可以在这里描述您所要分享的资源或资料（5000字以内）',
                modules: {
                    toolbar: {
                        container: toolbarOptions,  // 工具栏
                        handlers: {
                            'image': (value => {
                                if (value) {
                                    document.querySelector('.uploadImage input').click()
                                } else {
                                    this.quill.format('image', false);
                                }
                            })
                        }
                    }
                }
            }
        };
    },
    methods: {
        handleBeforeUpload() { },
        // 图片
        handleSuccess(res, file) {
            let quill = this.$refs.QuillEditor.quill
            let length = quill.getSelection().index;
            quill.insertEmbed(length, 'image', websiteConfigs.sourceUrl + res.data.file)
            quill.setSelection(length + 1)
        },
        //文章缩略图上传
        handleAvatarSuccess(file, x) {
            if (file.code == -200) {
                this.fileName = "",
                    this.$message.error(file.msg);
                return false;
            }
            //上传成功后
            this.img = file.data.file;
        },
        //附件上传
        handleFileSuccess(file, x) {
            if (file.code == -300) {
                this.$message.error("文件太大或不支持的文件格式");
                return false;
            }
            if (this.fileLists.length > 4) {
                this.$message.error("最多只能上传5个附件");
                return false;
            }
            if (file.code == -200) {
                this.$message.error(file.msg);
                return false;
            }
            //上传成功后
            this.fileLists.push({
                hash: file.data.hash,
                path: file.data.file,
                name: this.fileName,
                size: this.converSize(file.data.size),
                date: this.converTime(parseInt(file.data.ctime) * 1000)
            });
        },
        //上传前，获取文件名称
        beforeFileUpload(file, x) {
            if (file.name) {
                var index = file.name.lastIndexOf(".");
                var suffix = file.name.substr(index + 1);
                if ("jpg" == suffix || "jpeg" == suffix || "png" == suffix || "gif" == suffix ||
                    "zip" == suffix || "rar" == suffix || "7z" == suffix ||
                    "pdf" == suffix || "doc" == suffix || "docx" == suffix || "ppt" == suffix
                    || "pptx" == suffix || "xls" == suffix || "xlsx" == suffix
                ) { } else {
                    this.$message.error("上传文件格式不正确，请上传jpg，jpeg，png，gif，zip，rar，doc，docx，ppt，pptx，xls，xlsx，pdf等格式的文件");
                    return false;
                }
            }

            this.fileName = file.name;
            this.uploadPress = 0;
        },
        //返回文章列表
        back() {
            this.$router.push("/user/article/1");
        },
        onUploadProgress(event, file, fileList) {
            this.uploadPress = event.percent;
        },
        onError(err, file, fileList) {
            this.$message.error(err);
        },
        //监听标题文字长度
        titleInput(text) {
            this.fontCount = text.length;
        },
        //提交表单
        submitForm(e) {
            let _this = this;
            //下载不需要点券或者费用
            // if (_this.radio == 1) {
            //     _this.download_point_count = 0;
            //     _this.download_price = 0;
            // } else {
            //     if (_this.download_point_count > 0) {
            //         //没有错误提示，则表示验证通过
            //         if (_this.downloadPointTips != "") {
            //             _this.$message.error(this.downloadPointTips);
            //             return;
            //         }
            //     } else if (_this.download_price > 0) {
            //         //没有错误提示，则表示验证通过
            //         if (_this.downloadPriceTips != "") {
            //             _this.$message.error(_this.downloadPriceTips);
            //             return;
            //         }
            //     } else {
            //         _this.$message.error("请设置下载所需点券数或者费用");
            //         return;
            //     }
            // }

            if (_this.radio == 1) {
                _this.download_point_count = 0;
                _this.download_price = 0;
            } else {
                if (_this.download_point_count > 0) {
                    //没有错误提示，则表示验证通过
                    if (_this.downloadPointTips != "") {
                        _this.$message.error(this.downloadPointTips);
                        return;
                    }
                } else {
                    _this.$message.error("请设置下载所需点券");
                    return;
                }
            }

            if (e == 2) {
                this.submit(e);
            } else {
                this.$confirm("确定申请发布吗，申请后将不可编辑?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                })
                    .then(() => {
                        this.submit(e);
                    })
                    .catch(() => { });
            }
        },
        submit(e) {
            //提交表单
            let _this = this;
            _this.loading = true;
            this.post(
                "/article/article/edit_article",
                {
                    title: _this.title,
                    content: _this.content,
                    cate_id: _this.cate_id,
                    tag: _this.tags,
                    download_point_count: _this.download_point_count,
                    download_price: _this.download_price,
                    review: e, //添加到待审核
                    img: _this.img,
                    annex: _this.fileLists,
                    id: _this.id
                },
                function (res) {
                    _this.loading = false;
                    if (res.code == 200) {
                        _this.$message({
                            type: "success",
                            message: "提交成功"
                        });
                        setTimeout(() => {
                            switch (e) {
                                case 2:
                                    _this.$router.push("/user/article/2");
                                    break;
                                case 3:
                                    _this.$router.push("/user/article/3");
                                    break;
                                case 4:
                                    _this.$router.push("/user/article/1");
                                    break;
                                default:
                                    break;
                            }
                        }, 10);
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },
        converTime(timestamp) {
            if (timestamp) {
                var time = new Date(timestamp);
                var y = time.getFullYear(); //getFullYear方法以四位数字返回年份
                var M = time.getMonth() + 1; // getMonth方法从 Date 对象返回月份 (0 ~ 11)，返回结果需要手动加一
                var d = time.getDate(); // getDate方法从 Date 对象返回一个月中的某一天 (1 ~ 31)
                var h = time.getHours(); // getHours方法返回 Date 对象的小时 (0 ~ 23)
                var m = time.getMinutes(); // getMinutes方法返回 Date 对象的分钟 (0 ~ 59)
                var s = time.getSeconds(); // getSeconds方法返回 Date 对象的秒数 (0 ~ 59)
                return y + "-" + M + "-" + d + " " + h + ":" + m + ":" + s;
            } else {
                return "";
            }
        },
        converSize(limit) {
            limit = parseFloat(limit);
            var size = "";
            if (limit < 0.1 * 1024) {
                //如果小于0.1KB转化成B
                size = limit.toFixed(2) + "KB";
            } else if (limit < 0.1 * 1024 * 1024) {
                //如果小于0.1MB转化成KB
                size = (limit / 1024).toFixed(2) + "MB";
            } else if (limit < 0.1 * 1024 * 1024 * 1024) {
                //如果小于0.1GB转化成MB
                size = (limit / (1024 * 1024)).toFixed(2) + "GB";
            } else {
                //其他转化成GB
                size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "TB";
            }

            var sizestr = size + "";
            var len = sizestr.indexOf(".");
            var dec = sizestr.substr(len + 1, 2);
            if (dec == "00") {
                //当小数点后为00时 去掉小数部分
                return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
            }
            return sizestr;
        },
        getUploadHeader() {
            this.imgUploadUrl =
                this.websiteConfigs.baseUrl +
                this.websiteConfigs.uploadUrl +
                "?group=article"; //获取上传地址
            this.imgHeaders.token = sessionStorage.getItem("token");
            this.imgHeaders.timestamp = new Date().getTime();
            this.imgHeaders.secret = this.websiteConfigs.secret;
        },
        //获取文章分类
        getCate() {
            let _this = this;
            this.post("/article/index/cate", {}, function (res) {
                if (res.code == 200) {
                    _this.cateList = res.data;
                } else {
                    _this.$message.error(res.msg);
                }
            });
        },
        //删除附件
        delAnnex(hash) {
            this.$confirm("删除后将不可恢复, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消"
            })
                .then(() => {
                    this.fileLists = this.removeArray(this.fileLists, hash);
                })
                .catch(() => { });
        },
        //删除数组
        removeArray(_arr, _hash) {
            let length = _arr.length;
            for (let i = 0; i < length; i++) {
                if (_arr[i]["hash"] === _hash) {
                    if (i === 0) {
                        _arr.shift(); //删除并返回数组的第一个元素
                        return _arr;
                    } else if (i === length - 1) {
                        _arr.pop(); //删除并返回数组的最后一个元素
                        return _arr;
                    } else {
                        _arr.splice(i, 1); //删除下标为i的元素
                        return _arr;
                    }
                }
            }
        },
        //获取文章内容
        getDetail() {
            let _this = this;
            this.post(
                "/article/article/get_article_info",
                { id: _this.$route.params.id },
                function (res) {
                    if (res.code == 200) {
                        _this.title = res.data.title;
                        _this.id = _this.$route.params.id;
                        _this.content = res.data.content;
                        _this.cate_id = res.data.cate_id.key;
                        _this.tags = res.data.tag ? res.data.tag : "";
                        _this.img = res.data.img;
                        _this.fileLists = res.data.annex ? res.data.annex : [];
                        _this.download_point_count = res.data
                            .download_point_count
                            ? res.data.download_point_count
                            : 0;
                        _this.download_price = res.data.download_price
                            ? res.data.download_price
                            : 0;
                        if (
                            _this.download_price > 0 ||
                            _this.download_point_count > 0
                        ) {
                            _this.radio = 2;
                        }
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },
        //清除富文本编辑器内容
        clearContent() {
            if (
                this.content ==
                "您可以在这里描述您所要分享的资源或资料（5000字以内）"
            ) {
                this.content = "";
            }
        },
        //是否需要支付切换事件
        change(val) {
            let _this = this;
            if (val == 1) {
                _this.download_point_count = 0;
                _this.download_price = 0;
            }
        },
        //下载需点券输入框监听事件
        downloadPointInput(e) {
            let r = /^\+?[0-9][0-9]*$/; //正整数
            if (r.test(e)) {
                if (this.download_point_count > 10000) {
                    this.downloadPointTips = "下载点券数不能超过1万";
                } else {
                    this.downloadPointTips = "";
                }
            } else {
                this.downloadPointTips =
                    "下载点券数格式不正确，必须是大于0的整数";
            }
        },
        //下载需 费用输入框监听事件
        downloadPriceInput(e) {
            let r = /^-?\d+\.?\d{0,2}$/; //保留2位小数
            if (r.test(e)) {
                this.downloadPriceTips = "";
            } else {
                this.downloadPriceTips =
                    "下载费用值格式不正确，最多保留2位小数";
            }
        },
        //监听内容输入事件
        contentInputEvent() {
            if (
                this.content ==
                "您可以在这里描述您所要分享的资源或资料（5000字以内）"
            ) {
                this.contentTips = "contentFontColorGreen";
            } else {
                this.contentTips = "";
            }
        }
    },
    created() {
        this.getCate(); //获取分类列表
        if (this.$route.params.id > 0) {
            this.getDetail();
        }
        this.getUploadHeader(); //获取上传相关信息
    }
};
</script>
<style lang="less">
@import "../../../styles/article.less";
.el-select {
    width: 240px;
}
.edit-box {
    position: relative;
}
.edit-box .title_tip {
    font-size: 16px;
    position: absolute;
    right: 30px;
    top: 40px;
    color: rgb(209, 206, 206);
}
.upload-items .upload.uploaded .upload-txt {
    width: 74%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.edit-bd .el-form-item .el-form-item__content .tips {
    cursor: pointer;
}
.edit-wrap .edit-hd {
    padding: 0;
    height: 60px;
}
.edit-wrap .edit-hd .title {
    padding-left: 0;
}
.article_edit_box {
    .el-form-item__label {
        width: 220px !important;
        float: left;
    }
    .el-form-item__content {
        width: 700px;
        margin: 0 !important;
        float: left;
    }
}
.article_save {
    padding-left: 200px !important;
    .el-form-item__content {
        width: 300px;
    }
}
.downlaod_count_item {
    width: 100%;
    height: 40px;
    .el-input,
    span {
        float: left !important;
        width: 200px !important;
        height: 36px !important;
        line-height: 36px !important;
    }
}
.downloadPointTips {
    color: red;
    font-size: 12px;
}
.contentFontColorGreen {
    p {
        color: #999 !important;
        opacity: 0.7;
    }
}
.uploadImage,
.uploadFile {
    width: 0;
    height: 0;
    display: none;
}
</style>




